<template>
  <div class="container-fluid">
    <div class="row row--grid">
      <!-- breadcrumb -->
      <div class="col-12">
        <ul class="breadcrumb">
          <li class="breadcrumb__item"><router-link to="/en">Home</router-link></li>
          <li class="breadcrumb__item breadcrumb__item--active">About</li>
        </ul>
      </div>
      <!-- end breadcrumb -->

      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>Introducing AI Teading</h2>
          <p>
            Artificial Intelligence is one of the technologies that will bring innovation in the future.
            <br/>
            AI Teading is a <b>website where you can easily experience artificial intelligence technology</b>.
            <br/>
            On this site, you can use artificial intelligence applications released as open source on the site or experience them on external sites linked to the site.
            <br/>
            This site allows you to experience artificial intelligence technologies in various fields, and is beneficial for those who are interested in the future of artificial intelligence.
            <br/>
            Experience the surprises and potential applications of artificial intelligence at AI Teading!
          </p>
        </div>
      </div>
      <!-- end title -->

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">01</span>
          <h3 class="step__title">Easy to Experience</h3>
          <p class="step__text">Most apps require no special instructions and can be experienced with a few clicks. Click on any app to find out more about artificial intelligence.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">02</span>
          <h3 class="step__title">Many different types</h3>
          <p class="step__text">There are so many different apps that it takes time to experience just one. Try out the various artificial intelligence apps, focusing on your areas of interest.</p>
        </div>
      </div>

      <div class="col-12 col-lg-4">
        <div class="step">
          <span class="step__number">03</span>
          <h3 class="step__title">Well categorized</h3>
          <p class="step__text">The search area and sidebar make it easy to find apps in your area of interest. Try using the classification feature to find apps in a particular field.</p>
        </div>
      </div>
    </div>

    <div class="row row--grid">
      <!-- title -->
      <div class="col-12">
        <div class="main__title">
          <h2>FAQ about AI Teading</h2>
          <p>The following is a list of questions you may have about AI Teading. If you have any further questions, please contact us at the e-mail address below.</p>
        </div>
      </div>

      <div class="col-12">
        <a href="mailto:codingteading@gmail.com" class="main__load my-2" style='width: 100%; max-width: 250px'>codingteading@gmail.com</a>
      </div>
      <!-- end title -->

      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">Is there an additional charge?</h3>
          <p class="feature__text">All applications included in AI Teading are free. You can use all features without worrying about paying fees. However, for external sites, fee payment may be required depending on how the site operates.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">Can I publish my app externally?</h3>
          <p class="feature__text">We would be happy to release the apps contained on the site to external parties. You are free to publish and use them for commercial and non-commercial purposes. If the app is an open source app, you may copy and transform it for your own use.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">How was the site produced?</h3>
          <p class="feature__text">We spent a long time searching for artificial intelligence apps that are open source, publicly available, and run on the web. We took selected apps and listed and categorized them. We made the source available to the public and transformed it to the extent possible to comply with the license.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">What do I do with apps that don't work?</h3>
          <p class="feature__text">The apps included on the site were tested on the Chrome browser on PCs. Please use a Chrome-based browser on a PC that performs better than a mobile device. If it still does not work, please email us.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">What is the purpose of creating the site?</h3>
          <p class="feature__text">Despite the rapid development of artificial intelligence and the variety of apps available to the public, it was difficult to access easily. We created this site with the aim of creating an environment where anyone can easily experience artificial intelligence.</p>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="feature">
						<span class="feature__icon">
							<svg height="511pt" viewBox="-106 1 511 511.999" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m150.324219 0c-82.613281 0-149.824219 67.210938-149.824219 149.824219v1.867187c0 26.195313 21.3125 47.503906 47.503906 47.503906 7.296875 0 14.308594-1.617187 20.839844-4.808593 16.449219-8.039063 26.667969-24.398438 26.667969-42.695313v-1.867187c0-30.222657 24.589843-54.8125 54.8125-54.8125 30.226562 0 54.816406 24.589843 54.816406 54.8125 0 30.226562-24.589844 54.816406-54.816406 54.816406h-7.222657c-26.195312 0-47.503906 21.308594-47.503906 47.503906v78.492188c0 26.195312 21.308594 47.507812 47.503906 47.507812 26.195313 0 47.503907-21.3125 47.503907-47.507812v-36.480469c64.171875-17.871094 109.542969-76.691406 109.542969-144.332031.003906-82.613281-67.207032-149.824219-149.824219-149.824219zm0 0"/><path d="m142.324219 396.175781c-31.933594 0-57.910157 25.980469-57.910157 57.914063 0 31.929687 25.980469 57.910156 57.910157 57.910156 31.933593 0 57.910156-25.980469 57.910156-57.910156 0-31.933594-25.976563-57.914063-57.910156-57.914063zm0 0"/></svg>
            </span>
          <h3 class="feature__title">How can I suggest improvements?</h3>
          <p class="feature__text">There may be artificial intelligence applications that we have not yet found through coding tidings. There may also be good ways to improve misclassification and usability; if you would like to contribute to the development of AI Teading, please send us an email with your suggestions.</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "EN_About",
  data: function() {
    return {
    };
  },
  created() {
    $('html, body').animate({
      scrollTop: 0
    }, 250);
  }
}
</script>
